import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: "newest",
    numberNow: 0,
};

export const sortSlice = createSlice({
    name: "sort",
    initialState,
    reducers: {
        setSort: (state, action) => {
            state.value = action.payload.toString();
        },
        setNumberNow: (state, action) => {
            state.numberNow = action.payload;
        },
    },
});

export const { setSort, setNumberNow } = sortSlice.actions;

export default sortSlice.reducer;
