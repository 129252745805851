import React from "react";

const DeleteConfirmation = ({ isOpen, onClose, onConfirm, itemName }) => {
  if (!isOpen) return null;

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength)}...`;
  };

  const truncatedItemName = truncateText(itemName, 15); // Set the max length as needed

  return (
    <div
      className="overlay fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center"
      style={{ zIndex: "102" }}
    >
      <div className="sent error_message p-6 bg-white rounded shadow-lg w-full max-w-md">
        <p className="text-xl mb-4">Delete Category</p>
        <p className="">Do you want to delete</p>
        <p className="font-bold">{truncatedItemName}?</p>
        <div className="flex mt-4">
          <button
            type="button"
            className="close_button !bg-red-400 w-20 mr-2 text-white rounded"
            onClick={onClose}
          >
            Close
          </button>
          <button
            type="button"
            className="close_button bg-blue-500 w-20 text-white rounded"
            onClick={onConfirm}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
