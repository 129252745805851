import { signIn } from "../../../../js/api";

export const LoginTester = async () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("userInfo");
  console.log("jalan");

  try {
    const res = await signIn({
      email: "tester123@mail.com",
      password: "$Tester123",
    });

    localStorage.setItem("access_token", res.data.access_token);
    localStorage.setItem("userInfo", res.data.email + ";" + res.data.name);
  } catch (error) {
    console.error("Failed to sign in:", error);
  }
};
