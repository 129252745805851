import "./Logs.css";
import logs from "./logs.json";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { setToggle } from "../../app/searchSlice";
import { setNumberNow } from "../../js/app/sortSlice";
import { useEffect, useState } from "react";
import { setSearch, setToggle } from "../../js/app/searchSlice";

let newLogs;
const numberInPage = 2;
let slug;
const Logs = () => {
  const dispatch = useDispatch();
  const selects = useSelector((state) => state.sort.value);
  const search = useSelector((state) => state.search.value);
  const toggle = useSelector((state) => state.search.toggle);
  const numberNow = useSelector((state) => state.sort.numberNow); // 0 - 4
  const [tabs, setTabs] = useState(1);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // After render turn off auto search
    // dispatch(setToggle(false));
    setTabs(0);
  }, [search]);

  if (selects === "newest") {
    newLogs = logs.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  }

  if (selects === "oldest") {
    newLogs = logs.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  }

  let logsMap;
  let totalPages = newLogs.length / numberInPage;
  totalPages = Math.ceil(totalPages);
  const searchTerm = search.split(" ");

  // Search function
  if (toggle === true) {
    newLogs = newLogs.filter((item) => {
      if (search.toLowerCase() === "") return item;
      else {
        return (
          searchTerm.every((term) =>
            item.title.toLowerCase().includes(term.toLowerCase())
          ) ||
          searchTerm.every((term) => {
            return item.tags
              .filter((tag) => {
                return tag.includes(term.toLowerCase());
              })
              .join("\n");
          })
        );
      }
    });

    totalPages = newLogs.length / numberInPage;
    totalPages = Math.ceil(totalPages);

    // LOGS CARD
    logsMap = newLogs
      .slice(0 + numberNow * numberInPage, numberInPage * (numberNow + 1))
      .map((item) => {
        const tags = item.tags.map((i, index) => (
          <div
            className="tag cursor-pointer"
            key={index}
            onClick={() => {
              dispatch(setSearch(i));
              window.scrollTo({ top: 0, behavior: "smooth" });
              dispatch(setToggle(false));
            }}
          >
            <p>{i.toLowerCase()}</p>
          </div>
        ));

        const arr = item.date.split(",");
        const newDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
        slug = `${item.title
          .toLowerCase()
          .replaceAll(" ", "_")}-${item.id.toString()}`;

        return (
          <nav className="log" key={item.id}>
            <div className="log_detail">
              <div className="log_heading ">
                <Link to={slug}>
                  <h1 className="underline decoration-3">{item.title}</h1>
                </Link>
                <p>Date added : {newDate}</p>
              </div>
              <div className="tags">{tags}</div>
            </div>
          </nav>
        );
      });
  } else if (toggle === false) {
    logsMap = newLogs
      .slice(0 + numberNow * numberInPage, numberInPage * (numberNow + 1))
      .map((item) => {
        const tags = item.tags.map((i, index) => (
          <div
            className="tag cursor-pointer"
            key={index}
            onClick={() => {
              dispatch(setSearch(i));
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <p>{i.toLowerCase()}</p>
          </div>
        ));

        const arr = item.date.split(",");
        const newDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
        slug = `${item.title
          .toLowerCase()
          .replaceAll(" ", "_")}-${item.id.toString()}`;

        return (
          <div className="log" key={item.id}>
            <div className="log_detail">
              <div className="log_heading ">
                <Link to={slug}>
                  <h1 className="underline decoration-3">{item.title}</h1>
                </Link>
                <p>Date added : {newDate}</p>
              </div>
              <div className="tags">{tags}</div>
            </div>
          </div>
        );
      });
  }

  let pages = [];
  for (let i = 0; i < totalPages; i++) {
    pages[i] = i + 1;
  }

  // PAGES
  let paginationPages = 2;
  const pagesMap = pages
    .slice(0 + paginationPages * tabs, paginationPages + paginationPages * tabs)
    .map((e, index) => {
      return (
        <p
          key={index}
          className={
            index === numberNow - paginationPages * tabs
              ? "btn_white"
              : "btn_normal"
          }
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            dispatch(setNumberNow(e - 1));
          }}
        >
          {e}
        </p>
      );
    });

  return (
    <div className="logsMap" style={{ minHeight: "46vh" }}>
      <p className="warning">*Click the underlined text to open the Content</p>
      {newLogs.length === 0 ? (
        <p
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Data found!
        </p>
      ) : (
        logsMap
      )}
      <div
        style={{
          marginTop: "80px",
          display: "flex",
          color: "white",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          gap: "16px",
        }}
      >
        {newLogs.length === 0 ? (
          <p></p>
        ) : (
          <>
            <p
              style={{
                color: "var(--blue)",
                marginRight: "12px",

                cursor: "pointer",
              }}
              onClick={() => {
                if (tabs !== 0) {
                  setTabs(tabs - 1);
                  dispatch(setNumberNow(tabs * paginationPages - 1));
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              }}
            >
              {`<<`}
            </p>
            {pagesMap}
            <p
              style={{
                color: "var(--blue)",
                marginLeft: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (tabs + 1 < Math.ceil(totalPages / paginationPages)) {
                  setTabs(tabs + 1);
                  dispatch(setNumberNow(paginationPages * (tabs + 1)));
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              }}
            >
              {`>>`}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Logs;

export function loader({ params }) {
  let number = params.id.split("-");
  const logId = number[1] - 1;
  newLogs = logs.sort((a, b) => {
    return a.id - b.id;
  });
  return newLogs[logId];
}
