import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { setToggleLoading, setTogglePopUp } from "../../js/app/popupSlice"; // Adjust import path as needed
import Category from "../../js/app/users/category"; // Adjust import path as needed
import DeleteConfirmation from "./DeleteConfirmation"; // Adjust import path as needed
import { setSelectedCategory } from "../../js/app/users/timeManagementSlice"; // Adjust import path as needed

const CategoryPopUp = () => {
  const categoryData = useSelector(
    (state) => state.timeManagements?.categoryData
  );
  const selectedCategory = useSelector(
    (state) => state.timeManagements?.selectedCategory
  );
  const [newCategoryName, setNewCategoryName] = useState("");
  const [editValue, setEditValue] = useState("");
  const [editCategory, setEditCategory] = useState(null);
  const [deleteCategoryName, setDeleteCategoryName] = useState("");
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    Category.fetchCategories(dispatch);
  }, [dispatch]);

  const handleToggle = () => {
    dispatch(setTogglePopUp(false));
  };

  const handleAdd = () => {
    if (!newCategoryName) return;
    dispatch(setToggleLoading(true));
    Category.postNewCategory({ name: newCategoryName }, dispatch)
      .then(async () => {
        await Category.fetchCategories(dispatch);
        dispatch(setToggleLoading(false));
      })
      .catch((error) => {
        console.error("Error occurred in newCategory:", error);
        dispatch(setToggleLoading(false));
        dispatch(setTogglePopUp("error"));
      });
    setNewCategoryName("");
  };

  const handleEdit = (category) => {
    const categoryToEdit = categoryData.find((cat) => cat.name === category);
    if (categoryToEdit) {
      setEditCategory(categoryToEdit);
      setEditValue(categoryToEdit.name);
      setNewCategoryName(""); // Clear the input value for new category when editing
    }
  };

  const handleSaveEdit = () => {
    if (editCategory) {
      dispatch(setToggleLoading(true));
      Category.patchCategory(editCategory.name, { name: editValue }, dispatch)
        .then(async () => {
          await Category.fetchCategories(dispatch);
          dispatch(setToggleLoading(false));
          setEditCategory(null);
          setEditValue("");
          dispatch(setSelectedCategory("")); // Reset selected category after patch
        })
        .catch((error) => {
          console.error("Error updating category:", error);
          dispatch(setToggleLoading(false));
          dispatch(setTogglePopUp("error"));
        });
    }
  };

  const handleDelete = (categoryName) => {
    setDeleteCategoryName(categoryName);
    setIsDeleteConfirmOpen(true);
  };

  const confirmDelete = () => {
    dispatch(setToggleLoading(true));
    Category.deleteCategory(deleteCategoryName, dispatch)
      .then(async () => {
        await Category.fetchCategories(dispatch);
        dispatch(setToggleLoading(false));
        setIsDeleteConfirmOpen(false);
        setDeleteCategoryName("");
        dispatch(setSelectedCategory("")); // Reset selected category after delete
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
        dispatch(setToggleLoading(false));
        setIsDeleteConfirmOpen(false);
        dispatch(setTogglePopUp("error"));
      });
  };

  return (
    <div className="relative inline-block">
      <DeleteConfirmation
        isOpen={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onConfirm={confirmDelete}
        itemName={deleteCategoryName}
      />
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{ zIndex: "101" }}
      >
        <div className="p-6 sent error_message rounded shadow-lg w-full max-w-md">
          <div className="flex justify-center mb-4">
            <p className="text-center " style={{ color: "#FFF" }}>
              Pick Category:
            </p>
          </div>
          <div className="flex items-center mb-4">
            <select
              className="px-4 py-2 border border-gray-300 rounded w-full truncate"
              onChange={(e) => {
                dispatch(setSelectedCategory(e.target.value));
                setEditCategory(null);
                setEditValue("");
              }}
              value={selectedCategory} // Preserve the selected category
            >
              <option value="">Select a category</option>
              {categoryData?.map((category) => (
                <option
                  key={category.name}
                  value={category.name}
                  title={category.name}
                >
                  {category.name.length > 20
                    ? `${category.name.slice(0, 20)}...`
                    : category.name}
                </option>
              ))}
            </select>
            <button
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded flex items-center justify-center"
              onClick={() => handleEdit(selectedCategory)}
              disabled={!selectedCategory} // Disable edit if no category is selected
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              className="ml-2 px-4 py-2 bg-red-500 text-white rounded flex items-center justify-center"
              onClick={() => handleDelete(selectedCategory)}
              disabled={!selectedCategory} // Disable delete if no category is selected
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="text"
              value={editCategory ? editValue : newCategoryName}
              onChange={(e) =>
                editCategory
                  ? setEditValue(e.target.value)
                  : setNewCategoryName(e.target.value)
              }
              className="px-4 py-2 border border-gray-300 rounded w-full"
              placeholder="Enter category name"
            />
            {!editCategory && (
              <button
                onClick={handleAdd}
                className="ml-2 px-4 py-2 bg-green-500 text-white rounded"
              >
                Add
              </button>
            )}
            {editCategory && (
              <button
                onClick={handleSaveEdit}
                className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            )}
          </div>
          <div className="flex space-x-2">
            <button
              onClick={handleToggle}
              className="flex-1 px-4 py-2 bg-gray-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPopUp;
