import "./Info2.css";
import ega from "../../assets/ega.png";
import pen from "../../assets/pen.svg";
import { Link } from "react-router-dom";

const Info2 = () => {
  function calculateDates() {
    const birthDateStr = "1996-06-10";
    const birthDate = new Date(birthDateStr);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      years--;
    }

    return years;
  }

  return (
    <>
      <div className="info2">
        <h1 className="heading font72">Welcome to the Web!</h1>
        <div>
          <div className="body flex py-28 min-[921px]:px-12 " name="info2">
            <div className="px-2 [max:1281px]:w-[65%]">
              <img src={pen} alt="pen" className="pen" />
              <p>
                Here, I work with many ideas that I can apply to many different
                technologies. You can check it in the{" "}
                <Link
                  to="/logs"
                  className="link"
                  style={{ textDecoration: "underline" }}
                >
                  logs
                </Link>{" "}
                section.
              </p>
              <p>
                I’m {calculateDates()} years old, and I was born in Indonesia.
                Currently, I am specializing in the Web Development field area.
              </p>
              <img src={ega} alt="ega" className="ega"></img>
              <p>
                Other than Programming, I’m also interested in other areas such
                as Music, Art, and Design.
              </p>
              <p>
                I graduated from college in 2020, I'm from Binus University. I
                took a major in Information Systems degree.
              </p>
            </div>
            <div className="img px-2 ml-8">
              <img src={ega} alt="ega"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info2;
