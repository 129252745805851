import React from "react";
import "./Content.css";
import { useState } from "react";

const RomanTo = () => {
  const [roman, setRoman] = useState("");
  const [number, setNumber] = useState("");
  const [green, setGreen] = useState({ romanBool: false, numberBool: false });
  const [romanTest, setRomanTest] = useState(false);
  const regex =
    /^M{0,3}(?:C[MD]|D?C{0,3})(?:X[CL]|L?X{0,3})(?:I[XV]|V?I{0,3})$/i;

  let romanToInt = function (s) {
    let number = 0;
    if (!regex.test(s) && s !== "") {
      setRomanTest(true);
      return (number = 0);
    } else {
      setRomanTest(false);
    }
    for (let i = 0; i < s.length; i++) {
      let digit = s.slice(i, i + 1);
      let check;
      switch (digit) {
        case "I":
          number += 1;
          break;
        case "V":
          check = s.slice(i - 1, i);
          if (check === "I") {
            number += 3;
          } else {
            number += 5;
          }
          break;
        case "X":
          check = s.slice(i - 1, i);
          if (check === "I") {
            number += 8;
          } else {
            number += 10;
          }
          break;
        case "L":
          check = s.slice(i - 1, i);
          if (check === "X") {
            number += 30;
          } else {
            number += 50;
          }
          break;
        case "C":
          check = s.slice(i - 1, i);
          if (check === "X") {
            number += 80;
          } else {
            number += 100;
          }
          break;
        case "D":
          check = s.slice(i - 1, i);
          if (check === "C") {
            number += 300;
          } else {
            number += 500;
          }
          break;
        case "M":
          check = s.slice(i - 1, i);
          if (check === "C") {
            number += 800;
          } else {
            number += 1000;
          }
          break;
        default:
      }
    }
    return number;
  };

  let intToRoman = function (num) {
    let result = "";
    if (num >= 3000) result = "MMM";
    if (num <= 0) result = "";
    if (num >= 0 && num <= 3000) {
      let total = num;
      while (total > 0) {
        if (total >= 900) {
          if (total >= 3000) {
            result = String("MMM");
            total -= 3000;
          }
          if (total >= 2000) {
            result += String("MM");
            total -= 2000;
          }
          if (total >= 1000) {
            result += String("M");
            total -= 1000;
          }
          if (total >= 900 && total < 1000) {
            result += String("CM");
            total -= 900;
          }
        }
        if (total >= 400) {
          if (total >= 500 && total < 1000) {
            result += String("D");
            total -= 500;
          } else if (total >= 400 && total < 500) {
            result += String("CD");
            total -= 400;
          }
          if (total >= 900 && total < 1000) {
            result += String("CM");
            total -= 900;
          }
        }
        if (total >= 90) {
          if (total >= 100) {
            result += String("C");
            total -= 100;
          }
          if (total >= 90 && total < 100) {
            result += String("XC");
            total -= 90;
          }
        }
        if (total >= 40) {
          if (total >= 50 && total < 100) {
            result += String("L");
            total -= 50;
          } else if (total >= 40 && total < 50) {
            result += String("XL");
            total -= 40;
          }
        }
        if (total >= 9) {
          if (total >= 10 && total < 40) {
            result += String("X");
            total -= 10;
          }
          if (total === 9) {
            result += String("IX");
            total -= 9;
          }
        }
        if (total < 10) {
          if (total >= 4) {
            if (total === 4) {
              result += String("IV");
              total -= 4;
            } else if (total >= 5) {
              result += String("V");
              total -= 5;
            }
          }
          if (total > 0 && total !== 4 && total !== 9) {
            result += String("I");
            total -= 1;
          }
        }
      }
    }

    return result;
  };
  return (
    <div className="content ">
      <div className="text">
        <p>
          Hello, this is my first log to my website. I hope we can learn
          something new from my website in the future. In this Log, I’m gonna
          show you a cool feature I encounter when solving the Leet Code
          problem. So, Roman to Number is a number system that turns any Roman
          numerals into numbers, for example, MMXXIII is the roman numeral for
          the year 2023, and MMI for the year 2001.
        </p>
        <p>
          Roman numerals represent a number system that was widely used
          throughout Europe as the standard writing system until the middle
          ages. Modern Roman numerals use seven letters to represent different
          numbers. There are I, V, X, L, C, D, and M, I value is 1, the V value
          is 5, the X value is 10, the L value is 50, the C value is 100, the D
          value is 500, and M value is 1000. A Rule is applied, the bigger
          letter is used first in the letter naming, for example, M is used
          first before D then C then L, and so on (MDLXVI is 1566). Another rule
          is if a letter (lower value) is used before the bigger letter for
          example IV, XIX, XL, and CD. it means the value for the bigger letter
          is being subtracted by the lower letter, so IV is 5 - 1 = 4, XIX, is
          10 + (10 – 1) = 19, XL is 50 – 10 = 40, and CD is 500 – 100 = 400. For
          the last example, the current year is MMXXIII, we can calculate the
          number as 1000 + 1000 + 10 + 10 + 3 = 2023. Here you can try to
          process any Roman numeral into a number.
        </p>
        <p>Cheers :D!!</p>
      </div>
      <div>
        <div>
          <h4 className={green.numberBool ? "green" : ""}>Number</h4>
          <input
            value={number}
            spellCheck="false"
            min="0"
            max="3000"
            onFocus={() => setGreen({ ...green, numberBool: true })}
            onBlur={() => setGreen({ ...green, numberBool: false })}
            onChange={(e) => {
              setNumber(e.target.value);
              if (e.target.value > 3000) {
                setNumber(3000);
              }
              setRoman(intToRoman(e.target.value));
            }}
          ></input>

          <div className="test">
            <h4 className={green.romanBool ? "green" : ""}>Roman</h4>
            {romanTest && <p className="error">not a valid roman value!</p>}
          </div>

          <input
            value={roman}
            spellCheck="false"
            onFocus={() => setGreen({ ...green, romanBool: true })}
            onBlur={() => setGreen({ ...green, romanBool: false })}
            onChange={(e) => {
              setRoman(e.target.value.toUpperCase());
              setNumber(romanToInt(e.target.value.toUpperCase()));
            }}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default RomanTo;
