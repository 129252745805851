import React from "react";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./LoginAdmin.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, verifyLogin } from "../../js/api";

const Admin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkAlreadyLogin();
  }, [navigate]);

  const checkAlreadyLogin = () => {
    if (
      localStorage.getItem("access_token") ||
      localStorage.getItem("access_token")
    ) {
      // navigate("/admin/dashboard");
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await signIn({ email: id, password });

      setNotification("false");
      setPassword("");
      setId("");

      let data = result.data;
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("userInfo", data.email + ";" + data.name);
      navigate("/admin/dashboard");
    } catch (err) {
      if (err.response.data.message) {
        setNotification("invalid");
        return;
      }
      setNotification("server");
    }
  };

  return (
    <div className="center_div">
      <div className="bg-black w-60 m-auto rounded-xl border_login py-8">
        <form onSubmit={handleSubmit}>
          <div className="container ">
            <p className="text-white paragraph">ID</p>
            <input
              className="input w-40 h-6 rounded-sm padding"
              spellCheck="false"
              type="text"
              style={{ color: "black", paddingRight: "8px" }}
              maxLength="60"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />

            <p className="text-white paragraph">PASS</p>
            <div>
              <input
                className=" input w-40 h-6  rounded-sm padding"
                spellCheck="false"
                maxLength="16"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                style={{
                  marginLeft: "-28px",
                  top: "-0.7px",
                  color: "black",
                }}
                className="w-4"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
            <div className="h-10">
              <p className=" mt-2 error text-center">
                {notification === "invalid"
                  ? "Incorrect ID and Password!"
                  : notification === "server"
                  ? "Server Error"
                  : null}
              </p>
            </div>
            <button className=" m-auto mt-8 button" type="submit">
              <p>LOGIN</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Admin;
