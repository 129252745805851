import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./searchSlice";
import sortReducer from "./sortSlice";
import userReducer from "./admin/userSlice";
import popupReducer from "./popupSlice";
import timeManagementReducer from "./users/timeManagementSlice";
import ipTableReducer from "./admin/ipTableSlice";

export const store = configureStore({
  reducer: {
    search: searchReducer,
    sort: sortReducer,
    users: userReducer,
    popup: popupReducer,
    timeManagements: timeManagementReducer,
    ipTable: ipTableReducer,
  },
});
