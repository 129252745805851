import ReactDOM from "react-dom";
import { CgClose } from "react-icons/cg";
import "./Message.css";
import { useState } from "react";
import * as api from "../../js/api/index";

const Message = ({ open, onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [texts, setTexts] = useState("");
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [modal, setModal] = useState(true);
  const [messageStatus, setMessageStatus] = useState("");
  const [result, setResult] = useState(false);

  if (!open) return null;

  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const empty = () => {
    setName("");
    setEmail("");
    setTexts("");
    setError(false);
  };

  const createMessage = async () => {
    const result = await api.createMessage({
      name,
      email,
      message: texts,
    });

    if (result) {
      setSent(true);
    }
    if (result.status === 201) {
      setResult(true);
    }
    if (
      result.status !== 200 &&
      result.message === "Request failed with status code 429"
    ) {
      setMessageStatus("Too many Attempts!");
    } else {
      setMessageStatus(result.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      name.length === 0 ||
      (email.length === 0 && !regex.test(email)) ||
      texts.length === 0 ||
      name.length > 24
    ) {
      setError(true);
    }

    if (name && email && regex.test(email) && texts) {
      createMessage();
      setModal(false);
      empty();
    }
  };

  return ReactDOM.createPortal(
    <>
      <div className="overlay" />
      {modal && (
        <div className="message_portal">
          <CgClose
            className="close"
            onClick={() => {
              empty();
              onClose();
            }}
          />
          <form className="box" onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <p className="margin">Your Name : </p>
              {error && name.length <= 0 ? (
                <p className="error">Name is required!</p>
              ) : (
                ""
              )}
              {error && name.length > 24 && (
                <p className="error">Name is too long!</p>
              )}
            </div>
            <input
              name="name"
              spellCheck="false"
              value={name}
              style={{ padding: "8px" }}
              onChange={(e) => setName(e.target.value)}
            ></input>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <p className="margin">Your E-mail Address :</p>
              {error && email.length <= 0 ? (
                <p className="error">Email is required!</p>
              ) : (
                ""
              )}
              {error && email.length !== 0 && !regex.test(email) ? (
                <p className="error">This is not a valid email format!</p>
              ) : (
                ""
              )}
            </div>
            <input
              name="email"
              spellCheck="false"
              value={email}
              style={{ padding: "8px" }}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <p className="margin">Message :</p>
              {error && texts.length <= 0 ? (
                <p className="error">Message cannot be empty!</p>
              ) : (
                ""
              )}
            </div>
            <textarea
              name="texts"
              value={texts}
              spellCheck="false"
              style={{ padding: "8px" }}
              onChange={(e) => {
                setTexts(e.target.value);
              }}
            ></textarea>
            <div className="button">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      )}
      {sent && (
        <div className="sent">
          {result === true ? (
            <p>Message Has been Sent!</p>
          ) : (
            <div className="error_message">
              <p>Message Failed to Send!</p>
              <p>{messageStatus}</p>
            </div>
          )}
          <button
            className="close_button"
            onClick={() => {
              setModal(true);
              setSent(false);
              setResult(false);
              onClose();
            }}
          >
            Close
          </button>
        </div>
      )}
    </>,
    document.getElementById("portal")
  );
};

export default Message;
