import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggle: false,
  message: "",
  toggleLoading: false,
};

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setMessagePopUp: (state, action) => {
      state.message = action.payload?.toString();
      state.toggle = !state.toggle; // Toggle state to trigger popup visibility
    },
    setTogglePopUp: (state, action) => {
      if (action) {
        state.toggle = action.payload;
        console.log(state.toggle, 123);
        return;
      }
      console.log("hahaha");
      state.toggle = !state.toggle; // Toggle state to trigger popup visibility
    },
    setToggleLoading: (state, action) => {
      state.toggleLoading = action.payload; // Toggle state to trigger popup visibility
    },
  },
});

export const { setMessagePopUp, setTogglePopUp, setToggleLoading } =
  popupSlice.actions;

export default popupSlice.reducer;
