import { createSlice } from "@reduxjs/toolkit";
import { getUsers } from "../../api";

const initialState = {
  value: null,
  totalCount: null,
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersSuccess: (state, action) => {
      state.value = action.payload.result;
      state.totalCount = action.payload.total;
    },
    setUsersFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setUsersStart, setUsersSuccess, setUsersFailure } =
  usersSlice.actions;

export const fetchUsers = () => async (dispatch) => {
  try {
    const result = await getUsers();
    dispatch(setUsersSuccess(result.data));
  } catch (error) {
    dispatch(setUsersFailure(error.message));
  }
};

export default usersSlice.reducer;
