import { getTargetsAPI, patchTargetAPI, postNewTargetAPI } from "../../api";
import { setMessagePopUp, setToggleLoading } from "../popupSlice";
import { setTargetData } from "./timeManagementSlice";

class Target {
  static async postNewTarget(body, dispatch) {
    try {
      await postNewTargetAPI(body);
    } catch (error) {
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async fetchTargets(dispatch) {
    try {
      const result = await getTargetsAPI();
      console.log(result);
      dispatch(setTargetData({ targetData: result.data.result }));
    } catch (error) {
      console.log(error.response.data.message);
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async patchTarget(params, body, dispatch) {
    try {
      const result = await patchTargetAPI(params, body);
    } catch (error) {
      console.log(error.response.data.message);
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }
}

export default Target;
