// paramsConverter.js

class ParamsConverter {
  static async convert(param) {
    let formattedParam = param;
    formattedParam = formattedParam?.toString().replaceAll(" ", "-_ee_ee-");
    return formattedParam;
  }
}

module.exports = ParamsConverter;
