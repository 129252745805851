import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { setToggleLoading, setTogglePopUp } from "../../js/app/popupSlice"; // Adjust import path as needed
import Target from "../../js/app/users/target";

const TargetPopUp = () => {
  const targetData = useSelector((state) => state.timeManagements.targetData);

  const [inputValue, setInputValue] = useState("");
  const [editValue, setEditValue] = useState("");
  const [editTarget, setEditTarget] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    Target.fetchTargets(dispatch);
  }, [dispatch]);

  const handleToggle = () => {
    dispatch(setTogglePopUp(false));
  };

  const handleAdd = () => {
    dispatch(setToggleLoading(true));
    Target.postNewTarget({ name: inputValue }, dispatch)
      .then(async () => {
        await Target.fetchTargets(dispatch);
        dispatch(setToggleLoading(false));
      })
      .catch((error) => {
        console.error("Error occurred in newActivity:", error);
        dispatch(setToggleLoading(false));
        dispatch(setTogglePopUp("error"));
      });
    setInputValue("");
  };

  const handleEdit = (target) => {
    setEditTarget(target);
    setEditValue(target.name);
  };

  const handleSaveEdit = () => {
    if (editTarget) {
      dispatch(setToggleLoading(true));
      Target.patchTarget(
        editTarget.name,
        { status: editTarget.status, name: editValue },
        dispatch
      )
        .then(async () => {
          await Target.fetchTargets(dispatch);
          dispatch(setToggleLoading(false));
          setEditTarget(null);
          setEditValue("");
        })
        .catch((error) => {
          console.error("Error updating target:", error);
          dispatch(setToggleLoading(false));
          dispatch(setTogglePopUp("error"));
        });
    }
  };

  const handleCheckboxChange = async (e) => {
    const { name, checked } = e.target;
    console.log(`Checkbox ${name} is now ${checked}`);
    const targetName = name.replace("target", "");
    const target = targetData.find((t) => t.name === targetName);

    if (target) {
      dispatch(setToggleLoading(true));
      await Target.patchTarget(
        target.name,
        { status: checked, name: target.name },
        dispatch
      )
        .then(async () => {
          await Target.fetchTargets(dispatch);
          dispatch(setToggleLoading(false));
        })
        .catch((error) => {
          console.error("Error updating target status:", error);
          dispatch(setToggleLoading(false));
          dispatch(setTogglePopUp("error"));
        });
    }
  };

  const sortedTargetData = targetData
    ?.slice()
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <div className="relative inline-block">
      <div
        className="overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{ zIndex: "101" }}
      >
        <div className="sent error_message p-6  rounded shadow-lg w-full max-w-md">
          <div className="flex justify-center">
            <p className="mb-4  text-center" style={{ color: "#FFF" }}>
              Add Target:
            </p>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="text"
              value={editTarget ? editValue : inputValue}
              onChange={(e) =>
                editTarget
                  ? setEditValue(e.target.value)
                  : setInputValue(e.target.value)
              }
              className="px-6 py-2 border border-gray-300 rounded w-full"
              placeholder="Enter value"
            />
            {!editTarget && (
              <button
                onClick={handleAdd}
                className="ml-2 px-4 py-2 bg-green-500 text-white rounded w-24"
              >
                +
              </button>
            )}
            {editTarget && (
              <button
                onClick={handleSaveEdit}
                className="ml-2 px-4 py-2 bg-blue-500 text-white rounded w-24"
              >
                Save
              </button>
            )}
          </div>

          <div className="mb-4 overflow-y-auto h-96">
            {sortedTargetData?.map((target, index) => (
              <div key={target.name}>
                <div className="flex items-center justify-between">
                  <label className="block text-white truncate w-3/4">
                    <input
                      type="checkbox"
                      name={`target${target.name}`}
                      checked={target.status}
                      onChange={handleCheckboxChange}
                      className="mr-2 bg-gray-700 text-white"
                    />
                    {target.name.length > 40
                      ? `${target.name.slice(0, 40)}...`
                      : target.name}
                  </label>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="text-white text-lg cursor-pointer px-2"
                    onClick={() => handleEdit(target)}
                  />
                </div>
                <p
                  style={{
                    color: "#FFF",
                    textAlign: "left",
                    lineHeight: "20px",
                    flexDirection: "column",
                  }}
                >
                  Created at: {new Date(target.createdAt).toLocaleString()}{" "}
                  <br />
                  {target.status === true && (
                    <span className="mb-1 text-green-500">
                      {"Completed: " +
                        new Date(target.updatedAt).toLocaleString()}
                    </span>
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className="flex space-x-2">
            <button
              onClick={handleToggle}
              className="flex-1 px-4 py-2 bg-gray-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetPopUp;
