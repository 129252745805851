import "./Logs.css";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../js/ScrollToTop";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Search from "../../components/Carousel/Search";
import { useSelector, useDispatch } from "react-redux";
import { setSort, setNumberNow } from "../../js/app/sortSlice";
import { Helmet } from "react-helmet-async";

const LogsLayout = () => {
  const dispatch = useDispatch();
  let selects = useSelector((state) => state.sort.value);

  return (
    <>
      <Helmet>
        <title>satriadega - Logs</title>
      </Helmet>
      <ScrollToTop />
      <Header />
      <div
        style={{
          minHeight: "75.8vh",
        }}
      >
        <div style={{ height: "0.1px" }}></div>
        <div style={{ maxWidth: "1920px", margin: "0 auto" }}>
          <div style={{ height: "140px" }}></div>
          <Search />
          <div className="logs">
            <form className="sort">
              <label name="sort">Sort:</label>
              <select
                name="sort"
                style={{ marginLeft: "16px", fontSize: "15px" }}
                value={selects}
                onChange={(e) => {
                  dispatch(setSort(e.target.value));
                  dispatch(setNumberNow(0));
                }}
                className="text-black"
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </form>
          </div>
        </div>
        <Outlet context={selects} />
      </div>
      <Footer />
    </>
  );
};

export default LogsLayout;
