import axios from "axios";
import { API } from ".";

export const sendAnalytics = async () => {
  try {
    const result = await axios.post("https://ipapi.co/json/");

    const { data } = result;
    await API.post(
      "/tools/analytics",
      {
        address: data.ip,
        name: "www.satriadega.com/u=" + JSON.stringify(getUserAgentInfo()),
        city: data.city,
        countryName: data.country_name,
        lat: data.latitude.toString(),
        long: data.longitude.toString(),
      },
      { "ngrok-skip-browser-warning": true }
    );
  } catch (error) {
    return error;
  }
};

function getUserAgentInfo() {
  const userAgent = navigator.userAgent;
  let os = "Unknown OS";
  let browser = "Unknown Browser";

  // Detect OS
  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  else if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
  else if (userAgent.indexOf("X11") !== -1) os = "UNIX";
  else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  else if (userAgent.indexOf("Android") !== -1) os = "Android";
  else if (userAgent.indexOf("like Mac") !== -1) os = "iOS";

  // Detect Browser
  if (userAgent.indexOf("Chrome") !== -1) browser = "Chrome";
  else if (userAgent.indexOf("Safari") !== -1) browser = "Safari";
  else if (userAgent.indexOf("Firefox") !== -1) browser = "Firefox";
  else if (
    userAgent.indexOf("MSIE") !== -1 ||
    userAgent.indexOf("Trident") !== -1
  )
    browser = "Internet Explorer";

  return { os, browser };
}
