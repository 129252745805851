import "./Footer.css";
import footer1 from "../../assets/footer1.svg";
import footer2 from "../../assets/footer2.svg";
import footer3 from "../../assets/footer3.svg";

const Footer = () => {
  let getYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="bar1">
        <h6>Copyright Satria Pandega {getYear}. All rights reserved.</h6>
      </div>
      <div className="bar2">
        <a
          href="https://www.linkedin.com/in/satria-pandega-a2483820b/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={footer1} alt="not-found" />
        </a>
        <a
          href="https://instagram.com/satriadega"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={footer2} alt="not-found" />
        </a>
        <a
          href="https://facebook.com/satria.pandega.96"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={footer3} alt="not-found" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
