import React, { useEffect, useState } from "react";
import "./Stopwatch.css";
import { useDispatch, useSelector } from "react-redux";
import Activity from "../../js/app/users/activity";
import { setToggleLoading, setTogglePopUp } from "../../js/app/popupSlice";
import { setSelectedCategory } from "../../js/app/users/timeManagementSlice";
import image1 from "../../assets/target.svg";
import image2 from "../../assets/reset.svg";
import image3 from "../../assets/category.svg";

const Stopwatch = ({ dateString }) => {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [isRunning, setIsRunning] = useState(false);
  const [label, setLabel] = useState("");
  const dispatch = useDispatch();
  const nowTime = useSelector((state) => state.timeManagements.nowTime);
  const timeData = useSelector((state) => state.timeManagements.timeData);
  const activityData = useSelector(
    (state) => state.timeManagements.activityData
  );
  const selectedCategory = useSelector(
    (state) => state.timeManagements.selectedCategory
  );
  const [statusActivity, setStatusActivity] = useState(null);

  useEffect(() => {
    setIsRunning(false);
    setTime({ hours: 0, minutes: 0, seconds: 0 });

    if (nowTime && activityData) {
      const t1 = new Date();
      const t2 = new Date(nowTime);
      const dif = (t1.getTime() - t2.getTime()) / 1000;
      setTime(calculateDif(dif));
      setIsRunning(true);
      setStatusActivity(activityData[0]?.status);
    }
  }, [nowTime, dateString, dispatch, activityData]);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          const seconds = prevTime.seconds + 1;
          const minutes = prevTime.minutes + Math.floor(seconds / 60);
          const hours = prevTime.hours + Math.floor(minutes / 60);

          return {
            hours: hours,
            minutes: minutes % 60,
            seconds: seconds % 60,
          };
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, nowTime]);

  const calculateDif = (dif) => {
    let timeleft = dif;
    let hours = Math.floor(timeleft / 3600);
    timeleft -= hours * 3600;
    let mins = Math.floor(timeleft / 60);
    timeleft -= mins * 60;
    let secs = timeleft;
    return { hours: hours, minutes: mins, seconds: Math.floor(secs) };
  };

  const newActivity = () => {
    dispatch(setToggleLoading(true));
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setIsRunning(false);
    Activity.postNewActivity(
      { status: "start", description: label, category: selectedCategory },
      dispatch
    )
      .then(async () => {
        await Activity.fetchGuestTime(dateString, dispatch);
        dispatch(setToggleLoading(false));
        clearAfterSubmit();
        setIsRunning(true);
        dispatch(setSelectedCategory("")); // Clear the selected category
      })
      .catch((error) => {
        console.error("Error occurred in newActivity:", error);
        dispatch(setToggleLoading(false));
        dispatch(setTogglePopUp("error"));
      });
  };

  const changeActivity = (activity) => {
    dispatch(setToggleLoading(true));
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setIsRunning(false);
    Activity.postChangeActivity(
      { status: activity, description: label, category: selectedCategory },
      dispatch
    )
      .then(async () => {
        await Activity.fetchGuestTime(dateString, dispatch);
        setStatusActivity(activity);
        dispatch(setToggleLoading(false));
        clearAfterSubmit();
        setIsRunning(true);
        dispatch(setSelectedCategory("")); // Clear the selected category
      })
      .catch((error) => {
        console.error("Error occurred in changeActivity:", error);
        dispatch(setToggleLoading(false));
        dispatch(setTogglePopUp("error"));
      });
  };

  const clearAfterSubmit = () => {
    setLabel("");
  };

  const formatTime = (timeValue) => {
    return timeValue < 10 ? `0${timeValue}` : timeValue;
  };

  return (
    <div className="stopwatch text-white mt-10 mb-10 ">
      <div className="bg-black max-w-md mx-auto mt-8 flex justify-center borderRadius_default flex-col py-24 responsiveMargin_default">
        <h1
          className="text-center mb-8"
          style={{ padding: "0", lineHeight: "0", fontSize: "32px" }}
        >
          {formatTime(time.hours)}:{formatTime(time.minutes)}:
          {formatTime(time.seconds)}
        </h1>
        <p
          className="text-center mt-4 font-semibold"
          style={{ fontSize: "18px" }}
        >
          Label
        </p>
        <input
          className="input_default"
          onChange={(e) => setLabel(e.target.value)}
          value={label}
        />

        <div className="buttons">
          {timeData && activityData ? (
            (activityData[0]?.status === "activity" ||
              activityData[0]?.status === "start" ||
              activityData[0]?.status === "rest" ||
              activityData[0]?.status === "sleep") && (
              <>
                <button onClick={() => changeActivity("activity")}>
                  New Activity!
                </button>
                <button className="rest" onClick={() => changeActivity("rest")}>
                  Rest
                </button>
                <button
                  className="sleep"
                  onClick={() => changeActivity("sleep")}
                >
                  Sleep
                </button>
              </>
            )
          ) : (
            <button onClick={newActivity}>Start your day!</button>
          )}
          <div className="flex justify-center space-x-4 mt-4">
            <img
              src={image2}
              alt="reset"
              className="w-8 h-8 px-1 py-1 bg-white object-cover rounded cursor-pointer"
              onClick={() => dispatch(setTogglePopUp("resetPopUp"))}
            />
            <img
              src={image1}
              alt="target"
              className="w-8 h-8 px-1 py-1 bg-white object-cover rounded cursor-pointer"
              onClick={() => dispatch(setTogglePopUp("targetPopUp"))}
            />
            <img
              src={image3}
              alt="category"
              className="w-8 h-8 px-1 py-1 bg-white object-cover rounded cursor-pointer"
              onClick={() => dispatch(setTogglePopUp("categoryPopUp"))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stopwatch;
