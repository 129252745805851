import { Helmet } from "react-helmet-async";

import webico from "../../assets/web.ico";

const BaseHelmet = () => {
  return (
    <Helmet>
      <title>satriadega - Personal Website</title>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;600&family=Roboto&family=Supermercado+One&display=swap');
      </style>
      <link rel="shortcut icon" href={webico} type="image/x-icon"></link>

      <meta charset="UTF-8" />

      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"
      />
      <link rel="canonical" href="https://www.satriadega.com" />
      <meta property="og:title" content="satriadega - Personal Website" />
      <meta property="og:site_name" content="satriadega" />
      <meta property="og:url" content="www.satriadega.com" />
      <meta
        property="og:description"
        content="Hello my name is Satriadega, check my Personal Website project. 
    This site is also used for my Public Learning Notes."
      />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default BaseHelmet;
