import ScrollToTop from "../js/ScrollToTop";
import Header from "../components/Header/Header";
import Carousel from "../components/Carousel/Carousel";
import Info1 from "../components/Info1/Info1";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirected = sessionStorage.getItem("redirected");
    const currentPath = window.location.pathname;

    if (!redirected && currentPath === "/") {
      sessionStorage.setItem("redirected", "true");
      navigate("/logs/time_management_app-2");
    }
  }, [navigate]);

  return (
    <div>
      <Helmet></Helmet>

      <ScrollToTop />
      <Header />

      <div style={{ minHeight: "26vh" }}>
        <Carousel />
        <Info1 />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
