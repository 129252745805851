import { useEffect, useState } from "react";
import { editUser } from "../../js/api";
import "../Message/Message.css";
import PopUp from "./PopUp";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../js/app/admin/userSlice";

const PopUpContent = ({ toggle, setToggle, data, setData }) => {
  const dispatch = useDispatch();
  const [toggle2, setToggle2] = useState(false);
  const editClass1 = "!text-white text-sm !justify-start";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (toggle === "editUser") {
      try {
        let result = await editUser(data.id, data);
        setToggle2(result.data.message);
        dispatch(fetchUsers());
        setToggle(false);
      } catch (err) {
        setToggle2(err.response.data.message);
      }
    }
  };

  return (
    <>
      {toggle && (
        <div className="overlay">
          <div className="sent">
            {toggle === "editUser" && (
              <>
                <p className="text-xl mb-4">Edit</p>

                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-1 mb-4"
                >
                  <div className="">
                    <p className={editClass1 + " "}>Name</p>
                  </div>
                  <input
                    type="text"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                  <div>
                    <p className={editClass1}>Email</p>
                  </div>
                  <input
                    type="email"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                  <div>
                    <p className={editClass1}>Role</p>
                  </div>
                  <select
                    value={data.role}
                    onChange={(e) => setData({ ...data, role: e.target.value })}
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                  </select>
                  <div className="flex !flex-row">
                    <button
                      type="button"
                      className="close_button !bg-red-400 w-20"
                      onClick={() => {
                        setToggle(false);
                      }}
                    >
                      Close
                    </button>

                    <button type="submit" className="close_button w-20">
                      Submit
                    </button>
                  </div>
                </form>
              </>
            )}
            {toggle === "deleteUser" && (
              <>
                <p className="text-xl mb-4">Delete</p>
                <p>Do you want to delete {data.name}?</p>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-1 mb-4"
                >
                  <div className="flex !flex-row">
                    <button
                      type="button"
                      className="close_button !bg-red-400 w-20"
                      onClick={() => {
                        setToggle(false);
                      }}
                    >
                      Close
                    </button>

                    <button type="submit" className="close_button w-20">
                      Submit
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
      <PopUp toggle={toggle2} setShowPopUp={setToggle2} />
    </>
  );
};

export default PopUpContent;
