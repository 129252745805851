// File: ActivityComponent.js
import React, { useEffect, useState } from "react";
import "./Stopwatch.css";
import { useSelector, useDispatch } from "react-redux";
import PieChart from "../PieChart/PieChart";
import DeleteConfirmation from "../PopUp/DeleteConfirmation"; // Adjust import path as needed
import Activity from "../../js/app/users/activity"; // Adjust import path as needed
import { setSelectedActivityStore } from "../../js/app/users/timeManagementSlice"; // Adjust import path as needed
import { setToggleLoading, setTogglePopUp } from "../../js/app/popupSlice";
import EditTimePopUp from "../PopUp/EditTimePopUp";

const ActivityComponent = ({ dateString }) => {
  const activityData = useSelector(
    (state) => state.timeManagements.activityData
  );

  const dispatch = useDispatch();

  const [totalAct, setTotalAct] = useState(0);
  const [totalRest, setTotalRest] = useState(0);
  const [totalSleep, setTotalSleep] = useState(0);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    let actTotal = 0;
    let restTotal = 0;
    let sleepTotal = 0;

    activityData?.forEach((act) => {
      const { status, duration } = act;

      if (status === "activity" || status === "start") {
        actTotal += duration;
      }
      if (status === "rest") {
        restTotal += duration;
      }
      if (status === "sleep") {
        sleepTotal += duration;
      }
    });

    setTotalAct(actTotal);
    setTotalRest(restTotal);
    setTotalSleep(sleepTotal);
  }, [activityData]);

  const totalDuration = totalSleep + totalRest + totalAct;
  const sleepPercentage = ((totalSleep / totalDuration) * 100).toFixed(2);
  const restPercentage = ((totalRest / totalDuration) * 100).toFixed(2);
  const actPercentage = ((totalAct / totalDuration) * 100).toFixed(2);

  const fetchedData = {
    labels: ["Sleep", "Rest", "Activity"],
    datasets: [
      {
        label: "amount",
        data: [sleepPercentage, restPercentage, actPercentage],
        backgroundColor: [
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const calculateDuration = (duration) => {
    let timeleft = duration;
    let hours = Math.floor(timeleft / 3600);
    timeleft -= hours * 3600;
    let mins = Math.floor(timeleft / 60);
    timeleft -= mins * 60;
    let secs = timeleft;

    const checkHours = (hours) => (hours > 0 ? `${hours}h` : "");
    const checkMins = (mins) => (mins > 0 ? `${mins}m` : "");
    const checkSecs = (secs) => `${secs}s`;

    return `${checkHours(hours)}${checkMins(mins)}${checkSecs(secs)}`;
  };

  const handleDeleteClick = (activity) => {
    setSelectedActivity(activity);
    setIsDeleteConfirmOpen(true);
  };

  const handleEditClick = (activity) => {
    dispatch(setSelectedActivityStore(activity));
    dispatch(setTogglePopUp("editTimePopUp"));
  };

  const confirmDelete = () => {
    dispatch(setToggleLoading(true));
    Activity.deleteActivity(selectedActivity.uuid, dispatch)
      .then(async () => {
        await Activity.fetchGuestTime(dateString, dispatch);
        dispatch(setToggleLoading(false));
        setIsDeleteConfirmOpen(false);
        setSelectedActivity(null);
      })
      .catch((error) => {
        console.error("Error occurred in deleteActivity:", error);
        setIsDeleteConfirmOpen(false);
        dispatch(setToggleLoading(false));
        dispatch(setTogglePopUp("error"));
      });
  };

  const activityMap = activityData?.map((act, id) => {
    let time = new Date(act.createdAt);

    const color =
      act.status === "start" || act.status === "away"
        ? "robotoFont newActivity"
        : act.status === "activity"
        ? "robotoFont activity"
        : act.status === "rest"
        ? "robotoFont rest"
        : act.status === "sleep" || act.status === "cancel sleep"
        ? "robotoFont sleep"
        : act.status === "afk"
        ? "robotoFont newActivity"
        : "";

    const duration = calculateDuration(act.duration);

    return (
      <tbody key={id}>
        <tr className={color}>
          <td className="px-2 py-2">{act.status}</td>
          <td className="px-2 py-2">{act.description}</td>
          <td className="px-2 py-2">
            <p>{duration}</p>
          </td>
        </tr>
        {act.Category?.name && (
          <tr className="bg-gray-400 robotoFont">
            <td className="px-2 py-2" colSpan="3">
              <div className="flex justify-between items-center gap-2">
                <p style={{ fontSize: "0.9em", lineHeight: "15px" }}>
                  {`Category name: ${act.Category.name}`}
                </p>
              </div>
            </td>
          </tr>
        )}
        <tr className="bg-gray-400 robotoFont">
          <td className="px-2 py-2" colSpan="3">
            <div className="flex justify-between items-center gap-2">
              <p style={{ fontSize: "0.9em", lineHeight: "15px" }}>
                {`posted at ${time}`}
              </p>
              <div className="flex gap-1">
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded mr-2 border border-black border-opacity-30"
                  onClick={() => handleEditClick(act)}
                >
                  Edit
                </button>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded border border-black border-opacity-30"
                  onClick={() => handleDeleteClick(act)}
                >
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    );
  });

  const options = {
    responsive: true,
  };

  return (
    <div className="mt-16 flex justify-center flex-col items-center gap-2">
      <DeleteConfirmation
        isOpen={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onConfirm={confirmDelete}
        itemName={selectedActivity?.description}
      />
      {activityData?.length >= 1 && (
        <>
          {activityData?.length > 1 && (
            <PieChart data={fetchedData} options={options} />
          )}
          <div className="mt-4 robotoFont text-blue-300">
            Total Rest: {calculateDuration(totalRest)}
          </div>
          <div className="mt-2 robotoFont text-blue-300">
            Total Sleep: {calculateDuration(totalSleep)}
          </div>
          <div className="mt-2 robotoFont text-blue-300 mb-8">
            Total Activity: {calculateDuration(totalAct)}
          </div>
          <div className="overflow-auto h-80 w-[90%] mx-auto responsiveMargin_default">
            <table
              style={{
                width: "100%",
                borderRadius: "5px",
                borderCollapse: "collapse",
              }}
            >
              {activityMap?.length > 0 && (
                <>
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "white",
                      zIndex: 1,
                    }}
                  >
                    <tr className="robotoFont">
                      <th className="px-2 py-2">Status</th>
                      <th className="px-2 py-2">Label</th>
                      <th className="px-2 py-2">Duration</th>
                    </tr>
                  </thead>
                  {activityMap}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityComponent;
