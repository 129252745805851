import React, { useState } from "react";

const FilterIpTable = ({ onFilter, setShowFilter }) => {
  const [filterIP, setFilterIP] = useState("");
  const [filterWebsite, setFilterWebsite] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [distinct, setDistinct] = useState("");
  const [range, setRange] = useState("");

  const handleFilter = () => {
    onFilter({
      filterIP: filterIP.trim(),
      filterWebsite: filterWebsite.trim(),
      filterCity: filterCity.trim(),
      filterCountry: filterCountry.trim(),
      distinct: distinct === "" ? undefined : distinct === "true",
      range,
    });
    setShowFilter(false);
  };

  const handleReset = () => {
    setFilterIP("");
    setFilterWebsite("");
    setFilterCity("");
    setFilterCountry("");
    setDistinct("");
    setRange("");
  };

  return (
    <div className="filter-ip-table p-4 bg-gray-100 rounded-md shadow-md w-full md:w-3/4 lg:w-1/2 mx-auto">
      <div className="mb-4">
        <label className="block font-bold mb-1">IP Address:</label>
        <input
          type="text"
          value={filterIP}
          onChange={(e) => setFilterIP(e.target.value.trim())}
          className="border p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Website:</label>
        <input
          type="text"
          value={filterWebsite}
          onChange={(e) => setFilterWebsite(e.target.value.trim())}
          className="border p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">City:</label>
        <input
          type="text"
          value={filterCity}
          onChange={(e) => setFilterCity(e.target.value.trim())}
          className="border p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Country:</label>
        <input
          type="text"
          value={filterCountry}
          onChange={(e) => setFilterCountry(e.target.value.trim())}
          className="border p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Distinct:</label>
        <select
          value={distinct}
          onChange={(e) => setDistinct(e.target.value)}
          className="border p-2 w-full"
        >
          <option value="">Select</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Range:</label>
        <input
          type="number"
          value={range}
          onChange={(e) => setRange(e.target.value)}
          className="border p-2 w-full"
        />
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleReset}
          className="bg-gray-500 text-white p-2 rounded w-1/2 mr-2"
        >
          Reset Filters
        </button>
        <button
          onClick={handleFilter}
          className="bg-blue-500 text-white p-2 rounded w-1/2 "
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
};

export default FilterIpTable;
