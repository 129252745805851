import "./Header.css";
import { CgMenu, CgClose } from "react-icons/cg";
import { useState } from "react";
import Clock from "./Clock";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);

  const clickHandler = () => {
    setOpen(!open);
  };

  const hamburgerIcon = (
    <CgMenu
      className="hamburger"
      size="40px"
      color="white"
      onClick={() => setOpen(!open)}
    />
  );

  const closeIcon = (
    <CgClose
      className="hamburger"
      size="40px"
      color="white"
      onClick={() => setOpen(!open)}
    />
  );

  return (
    <div className="long_header">
      <div className="main_header font26">
        <Link preventScrollReset={false} to="/" className="title">
          <p onClick={() => setOpen(false)}>satriadega.com</p>
        </Link>
        {open ? closeIcon : hamburgerIcon}
        <div className="menu">
          <Link to="/" className="text">
            Home
          </Link>
          <Link to="/aboutme" className="text">
            About Me
          </Link>
          <Link to="/logs" className="text">
            Logs
          </Link>
          <Link to="/contact" className="text">
            Contact
          </Link>
        </div>
        <div className="clock">
          <p>
            <Clock />
          </p>
        </div>
        {open && (
          <div className="menu_m font26 margin_left">
            <NavLink onClick={clickHandler} to="/">
              Home
            </NavLink>
            <NavLink onClick={clickHandler} to="/aboutme">
              About Me
            </NavLink>
            <NavLink onClick={clickHandler} to="/logs">
              Logs
            </NavLink>
            <NavLink onClick={clickHandler} to="/contact">
              Contact
            </NavLink>
            <div className="clock_m font26">
              <Clock />
            </div>
            <div className="warning">
              <p>*This clock does'nt use your data</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
