import React from "react";
import trash from "../../../assets/trash.png";
import edit from "../../../assets/edit.png";
import "./UsersTable.css";

const UsersTable = ({ data, setToggle, setData }) => {
  const tableHeaders = [
    "ID",
    "Name",
    "Email",
    "Role",
    "Created At",
    "Updated At",
    "Action",
  ];

  const convertToGMT7 = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { timeZone: "Asia/Bangkok" });
  };

  return (
    <table xtitle="Users List" className="border-2 usersTable">
      <thead className="bg-gray-100">
        <tr>
          {tableHeaders.map((header, index) => (
            <th key={index} className={header.toLowerCase().replace(/ /g, "-")}>
              <span>{header}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((el, index) => (
          <tr key={index} className="odd:bg-white even:bg-gray-50">
            <td className="border border-gray-300 p-2 md:p-4">{el.id}</td>
            <td className="border border-gray-300 p-2 md:p-4">{el.name}</td>
            <td className="border border-gray-300 p-2 md:p-4">{el.email}</td>
            <td className="border border-gray-300 p-2 md:p-4">{el.role}</td>
            <td className="border border-gray-300 p-2 md:p-4">
              {convertToGMT7(el.createdAt)}
            </td>
            <td className="border border-gray-300 p-2 md:p-4">
              {convertToGMT7(el.updatedAt)}
            </td>
            <td className="border border-gray-300 p-2 md:p-4">
              <div className="flex justify-around">
                <img
                  alt="edit"
                  src={edit}
                  width="20px"
                  className="cursor-pointer"
                  onClick={() => {
                    setToggle("editUser");
                    setData(el);
                  }}
                />
                <img
                  alt="delete"
                  src={trash}
                  width="20px"
                  className="cursor-pointer"
                  onClick={() => {
                    setToggle("deleteUser");
                    setData(el);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UsersTable;
