import React from "react";
import loadingGif from "../../assets/spinner.gif";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <img src={loadingGif} alt="Loading..." className="loading-gif" />
    </div>
  );
};

export default Loading;
