import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTogglePopUp } from "../../js/app/popupSlice"; // Adjust import path as needed

const ResetPopUp = () => {
  const [inputValue, setInputValue] = useState("");
  const [resetTime, setResetTime] = useState("05:00");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const dispatch = useDispatch();

  useEffect(() => {
    const storedResetTime = localStorage.getItem("resetTime");
    if (storedResetTime) {
      setResetTime(storedResetTime);
    }
  }, []);

  const handleToggle = () => {
    dispatch(setTogglePopUp(false)); // Hide the popup
  };

  const handleEnter = () => {
    // Regex to match valid 24-hour time format (HH:MM)
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;

    // Check if the inputValue matches the pattern
    if (timePattern.test(inputValue)) {
      localStorage.setItem("resetTime", inputValue);
      setInputValue("");
      dispatch(setTogglePopUp(false)); // Hide the popup
      setErrorMessage(""); // Clear error message
      window.location.reload();
    } else {
      setErrorMessage(
        "Invalid time format. Please enter time in HH:MM format."
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleEnter();
    }
  };

  return (
    <div className="relative inline-block">
      <div className="overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="sent error_message bg-white p-6 rounded shadow-lg">
          <p className="mb-4" style={{ color: "#fff" }}>
            Enter your Reset Time:
          </p>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            className="px-2 py-1 border border-gray-300 rounded mb-2 w-full"
            placeholder={resetTime}
          />
          {errorMessage && (
            <p className="text-red-500 mb-4" style={{ lineHeight: "16px" }}>
              {errorMessage}
            </p>
          )}
          <div className="flex space-x-2">
            <button
              onClick={handleToggle}
              className="flex-1 px-4 py-2 bg-gray-500 text-white rounded"
            >
              Close
            </button>
            <button
              onClick={handleEnter}
              className="flex-1 px-4 py-2 bg-green-500 text-white rounded"
            >
              Enter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPopUp;
