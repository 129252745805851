import React from "react";
import "./IpTable.css";

const IpTable = ({ data }) => {
  const tableHeaders = [
    "ID",
    "IP Address",
    "Website Name",
    "Created At",
    "Updated At",
    "City",
    "Country Name",
    "Latitude",
    "Longitude",
  ];

  const convertToGMT7 = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { timeZone: "Asia/Bangkok" });
  };

  return (
    <>
      <table xtitle="IP Address List" className="border-2 ipTable">
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th
                key={index}
                className={header.toLowerCase().replace(/ /g, "-")}
              >
                <span>{header}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((el, index) => (
            <tr key={index}>
              <td className="bg-white">{el.id}</td>
              <td className="bg-white">{el.ipAddress}</td>
              <td className="bg-white">{el.websiteName}</td>
              <td className="bg-white">{convertToGMT7(el.createdAt)}</td>
              <td className="bg-white">{convertToGMT7(el.updatedAt)}</td>
              <td className="bg-white">{el.city}</td>
              <td className="bg-white">{el.countryName}</td>
              <td className="bg-white">{el.latitude}</td>
              <td className="bg-white">{el.longitude}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default IpTable;
