import { createSlice } from "@reduxjs/toolkit";
import { getIpTable } from "../../api"; // Assume this is an API call to fetch IP data

const initialState = {
  value: null,
  error: null,
};

export const ipTableSlice = createSlice({
  name: "ipTable",
  initialState,
  reducers: {
    setIpTableSuccess: (state, action) => {
      state.value = action.payload;
    },
    setIpTableFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setIpTableSuccess, setIpTableFailure } = ipTableSlice.actions;

export const fetchIpTable = (params) => async (dispatch) => {
  try {
    console.log(params, "params");
    const result = await getIpTable(params);
    console.log(777, "jalan", result.result);
    dispatch(setIpTableSuccess(result.result));
  } catch (error) {
    dispatch(setIpTableFailure(error.message));
  }
};

export default ipTableSlice.reducer;
