import "./Graph.css";

const items = [
  {
    name: "Javascript",
    mastery: 75,
  },
  {
    name: "React",
    mastery: 50,
  },
  {
    name: "Redux",
    mastery: 45,
  },
  {
    name: "Express",
    mastery: 65,
  },
  {
    name: "MongoDB",
    mastery: 30,
  },
  {
    name: "Figma",
    mastery: 30,
  },
  {
    name: "MySQL",
    mastery: 40,
  },
  {
    name: "Java",
    mastery: 45,
  },
  {
    name: "Spring",
    mastery: 30,
  },
  {
    name: "VueJS",
    mastery: 45,
  },
  {
    name: "React Native",
    mastery: 45,
  },
  {
    name: "GraphQL",
    mastery: 35,
  },
  {
    name: "Redis",
    mastery: 65,
  },
];

// const first = items.sort((a, b) => a.name.localeCompare(b.name));

const Graph = () => {
  const mapItems = items.map((item, id) => {
    items.sort((a, b) => b.mastery - a.mastery || a.name.localeCompare(b.name));

    return (
      <div className="item" key={id}>
        <div className="w-full ">
          <div className="bar_out">
            <div className="bar_in" style={{ width: `${item.mastery}%` }}>
              <p>{`${item.mastery}%`}</p>
            </div>
          </div>
        </div>
        <div className="max-[921px]:w-full">
          <h6>{item.name}</h6>
        </div>
      </div>
    );
  });

  return (
    <div className="min-[921px]:ml-[2%] min-[921px]:mr-[2%]">
      <div className="graph">
        <h3 className="heading">Tools I utilized for Development</h3>
        <div>{mapItems}</div>
      </div>
    </div>
  );
};

export default Graph;
