// File: timeManagementSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nowTime: null,
  error: null,
  timeData: null,
  activityData: null,
  targetData: null,
  categoryData: null,
  selectedCategory: "", // Add selected category state
  selectedActivity: null, // Add selected activity state
  activityMonthlyData: null, // Add activity monthly data state
};

const timeManagementSlice = createSlice({
  name: "timeManagements",
  initialState,
  reducers: {
    setNowTimeSuccess: (state, action) => {
      state.nowTime = action.payload.nowTime;
      state.timeData = action.payload.timeData;
      state.error = null;
    },
    setNowTimeFailure: (state, action) => {
      state.error = action.payload;
      state.nowTime = null;
      state.timeData = null;
      state.activityData = null;
    },
    setActivityData: (state, action) => {
      state.activityData = action.payload.activityData;
    },
    setTargetData: (state, action) => {
      state.targetData = action.payload.targetData;
    },
    setCategoryData: (state, action) => {
      state.categoryData = action.payload.categoryData;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedActivityStore: (state, action) => {
      state.selectedActivity = action.payload;
    },
    setActivityMonthlyData: (state, action) => {
      state.activityMonthlyData = action.payload.activityMonthlyData;
    },
  },
});

export const {
  setNowTimeSuccess,
  setNowTimeFailure,
  setActivityData,
  setTargetData,
  setCategoryData,
  setSelectedCategory,
  setSelectedActivityStore,
  setActivityMonthlyData,
} = timeManagementSlice.actions;

export default timeManagementSlice.reducer;
