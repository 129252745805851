import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: "",
    toggle: false,
};

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.value = action.payload.toString();
        },
        setToggle: (state, action) => {
            void (state.toggle = action.payload);
        },
    },
});

export const { setSearch, setToggle } = searchSlice.actions;

export default searchSlice.reducer;
