import React from "react";
import ReactDOM from "react-dom";
import "./DeleteMessage.css";

const DeleteMessage = ({ open, onClose, deleteMessages }) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <div>
      <div className="overlay" />
      <div className="popupDelete">
        <p>Do you want to delete all messages ?</p>
        <div className="flex justify-center mt-8 gap-12">
          <button className="text-white" onClick={onClose}>
            Cancel
          </button>
          <button
            className="text-white"
            onClick={() => {
              deleteMessages();
              onClose();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default DeleteMessage;
