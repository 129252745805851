import React from "react";
import "./LogDetail.css";
import { useLoaderData } from "react-router-dom";
import RomanTo from "./Content/RomanTo";
import TimeManagement from "./Content/TimeManagement/TimeManagement";

// LOG-DETAILS/CONTENT
const LogDetail = () => {
  const log = useLoaderData();
  const content = (param) => {
    switch (param) {
      case 1:
        return <RomanTo />;
      case 2:
        return <TimeManagement />;
      default:
    }
  };

  let totalTags = "";
  for (let i = 0; i < log.tags.length; i++) {
    if (i === log.tags.length - 1) {
      totalTags += `#${log.tags[i]}.`;
      break;
    }
    totalTags += `#${log.tags[i]}, `;
  }

  return (
    <div className="detail">
      <h1>{log.title}</h1>
      <div>
        <h3>{totalTags}</h3>
      </div>
      {content(log.id)}
    </div>
  );
};

export default LogDetail;
