import ScrollToTop from "../js/ScrollToTop";
import "./ErrorPage.css";
import { Helmet } from "react-helmet-async";

const ErrorPage = () => {
  return (
    <div className="not_found">
      <Helmet>
        <title>satriadega - Page Not Found</title>
      </Helmet>
      <ScrollToTop />
      <nav>
        <h1>Error here!</h1>
      </nav>
      <nav>
        <h1>404 not found!</h1>
      </nav>
    </div>
  );
};

export default ErrorPage;
