import "./App.css";

import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Logs, { loader as logDetailLoader } from "./routes/Logs/Logs";

import Home from "./routes/Home";
import AboutMe from "./routes/AboutMe";
import ErrorPage from "./routes/ErrorPage";
import Contact from "./routes/Contact";
import LogDetail from "./routes/Logs/LogDetail";
import LogsLayout from "./routes/Logs/LogsLayout";
import Admin from "./routes/Admin/LoginAdmin";
import Dashboard from "./routes/Admin/Dashboard";
import BaseHelmet from "./components/BaseHelmet";
import { sendAnalytics } from "./js/api/analytics";
import PopUp from "./components/PopUp/PopUp";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/aboutme",
      element: <AboutMe />,
    },
    {
      path: "/logs",
      element: <LogsLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Logs />,
        },
        {
          path: ":id",
          element: <LogDetail />,
          loader: logDetailLoader,
        },
      ],
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/admin",
      element: <Admin />,
    },
    {
      path: "/admin/dashboard",
      element: <Dashboard />,
    },
  ]);

  sendAnalytics();

  return (
    <HelmetProvider>
      <BaseHelmet />
      <PopUp />

      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
