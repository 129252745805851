import {
  getGuestTimeAPI,
  postChangeActivityAPI,
  postNewActivityAPI,
  patchTimeAPI,
  deleteTimeAPI,
  getGuestTimeMonthlyAPI,
} from "../../api/index";
import { setMessagePopUp } from "../popupSlice";
import {
  setActivityData,
  setNowTimeSuccess,
  setNowTimeFailure,
  setActivityMonthlyData,
} from "./timeManagementSlice";

class Activity {
  static async fetchGuestTime(dateString, dispatch) {
    try {
      const result = await getGuestTimeAPI(dateString);
      const nowTime = result?.data.result;

      dispatch(setActivityData({ activityData: nowTime }));
      dispatch(
        setNowTimeSuccess({
          nowTime: nowTime[0]?.createdAt,
          timeData: nowTime[0],
        })
      );
    } catch (error) {
      console.log(error.response.data.message, 555);
      dispatch(setNowTimeFailure());
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async postNewActivity(body, dispatch) {
    try {
      await postNewActivityAPI(body);
    } catch (error) {
      dispatch(setNowTimeFailure());
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async postChangeActivity(body, dispatch) {
    try {
      await postChangeActivityAPI(body);
    } catch (error) {
      dispatch(setNowTimeFailure());
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async patchActivity(params, body, dispatch) {
    try {
      await patchTimeAPI(params, body);
    } catch (error) {
      dispatch(setNowTimeFailure());
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async deleteActivity(params, dispatch) {
    try {
      await deleteTimeAPI(params);
    } catch (error) {
      console.log(error.response.data.message);
      dispatch(setNowTimeFailure());
      dispatch(setMessagePopUp(error.response.data.message));

      throw error;
    }
  }

  static async fetchGuestTimeMonthly(year, month, dispatch) {
    try {
      const result = await getGuestTimeMonthlyAPI(year, month);
      dispatch(setActivityMonthlyData({ activityMonthlyData: result }));
    } catch (error) {
      dispatch(setNowTimeFailure());
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }
}

export default Activity;
