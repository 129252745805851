import "./Carousel.css";
import Search from "./Search";

const Carousel = () => {
  return (
    <div className="carousel">
      <div className="carousel_img">
        <div className="body">
          <h1 className="heading font72">Hello, my Name is Ega</h1>
          <h3>A Programmer specializing in Web Development</h3>
          <h6>
            This Website is mainly used for My personal log of features or
            functions that I can build in Developing a Website.
          </h6>
          <Search />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
