import { useEffect, useState } from "react";
import "./AdminPanel.css";
import PopUpContent from "../PopUp/PopUpContent";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../js/app/admin/userSlice";
import IpTable from "./IpTable/IpTable";
import UsersTable from "./UsersTable/UsersTable";
import { fetchIpTable } from "../../js/app/admin/ipTableSlice";
import FilterIpTable from "./IpTable/FilterIpTable";
import { setToggleLoading } from "../../js/app/popupSlice";

const AdminPanel = ({ activityData }) => {
  const [menu, setMenu] = useState("users");
  const [data, setData] = useState({});
  const [toggle, setToggle] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.value);
  const ipData = useSelector((state) => state.ipTable.value);

  useEffect(() => {
    const setup = async () => {
      try {
        dispatch(setToggleLoading(true));
        await initialSetup(menu);
      } finally {
        dispatch(setToggleLoading(false));
      }
    };

    setup();
  }, [dispatch, menu]);

  const initialSetup = async (menu) => {
    try {
      if (menu === "users") {
        await dispatch(fetchUsers());
      } else if (menu === "logs") {
        await dispatch(fetchIpTable());
      }
    } catch (error) {
      console.error("Error during initial setup:", error);
    }
  };

  const handleFilter = (filters) => {
    dispatch(setToggleLoading(true));
    dispatch(fetchIpTable(filters)).then(() => {
      dispatch(setToggleLoading(false));
    });
  };

  return (
    <div className="m-auto relative">
      <div>
        <div className="flex">
          <select
            className="mx-auto mt-8"
            value={menu}
            onChange={(e) => setMenu(e.target.value)}
          >
            <option value="users">Users</option>
            <option value="logs">IP Logs</option>
          </select>
        </div>
        {menu === "logs" && (
          <div className="flex flex-col items-end xl:w-[1200px] xl:m-auto mt-4 relative mr-[3%]">
            <button
              onClick={() => setShowFilter(!showFilter)}
              className="bg-blue-500 text-white p-4 rounded"
            >
              Filter
            </button>
            {showFilter && (
              <>
                <div
                  className="overlay_default"
                  onClick={() => {
                    console.log("jalan bro");
                    setShowFilter(false);
                  }}
                ></div>
                <div className="relative w-full z-10">
                  <FilterIpTable
                    onFilter={handleFilter}
                    setShowFilter={setShowFilter}
                    resetFilter={() => {
                      dispatch(fetchIpTable());
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <div
          className="xl:w-[1200px] xl:m-auto mt-6 overflow-auto whitespace-nowrap ml-[3%] mr-[3%] mb-[3%]"
          style={{ marginTop: "40px", maxHeight: "600px" }}
        >
          {menu === "users" && (
            <UsersTable data={users} setToggle={setToggle} setData={setData} />
          )}
          {menu === "logs" && <IpTable data={ipData} />}
        </div>
      </div>

      <PopUpContent
        toggle={toggle}
        setToggle={setToggle}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default AdminPanel;
