import {
  getCategoriesAPI,
  patchCategoryAPI,
  postNewCategoryAPI,
  deleteCategoryAPI,
} from "../../api";
import { setMessagePopUp, setToggleLoading } from "../popupSlice";
import { setCategoryData } from "./timeManagementSlice";

class Category {
  static async postNewCategory(body, dispatch) {
    try {
      await postNewCategoryAPI(body);
    } catch (error) {
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async fetchCategories(dispatch) {
    try {
      const result = await getCategoriesAPI();
      console.log(result.data.result, 651);
      dispatch(setCategoryData({ categoryData: result.data.result }));
    } catch (error) {
      console.log(error.response.data.message);
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async patchCategory(params, body, dispatch) {
    try {
      const result = await patchCategoryAPI(params, body);
    } catch (error) {
      console.log(error.response.data.message);
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }

  static async deleteCategory(params, dispatch) {
    try {
      const result = await deleteCategoryAPI(params);
    } catch (error) {
      console.log(error.response.data.message);
      dispatch(setMessagePopUp(error.response.data.message));
      throw error;
    }
  }
}

export default Category;
