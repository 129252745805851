// File: EditTimePopUp.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToggleLoading, setTogglePopUp } from "../../js/app/popupSlice"; // Adjust import path as needed
import Activity from "../../js/app/users/activity"; // Adjust import path as needed
import Category from "../../js/app/users/category";

const EditTimePopUp = ({ dateString }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.popup.toggle); // Use toggle state from popup slice
  const selectedActivity = useSelector(
    (state) => state.timeManagements.selectedActivity
  );
  const categoryData = useSelector(
    (state) => state.timeManagements.categoryData
  );

  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    if (isOpen && selectedActivity) {
      setStatus(selectedActivity.status);
      setDescription(selectedActivity.description);
      setCategory(selectedActivity.category);
    }
    Category.fetchCategories(dispatch);
    console.log(selectedActivity);
  }, [isOpen, selectedActivity, dispatch]);

  const handleToggle = () => {
    dispatch(setTogglePopUp(false));
  };

  const handleSave = async () => {
    if (!status) return;
    dispatch(setToggleLoading(true));

    const body = { status, description, category };

    try {
      console.log(selectedActivity);
      await Activity.patchActivity(selectedActivity.uuid, body, dispatch);
      await Activity.fetchGuestTime(dateString, dispatch);
      dispatch(setToggleLoading(false));
      handleToggle();
    } catch (error) {
      console.error("Error updating activity:", error);
      dispatch(setToggleLoading(false));
      handleToggle();
      dispatch(setTogglePopUp("error"));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="relative inline-block">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{ zIndex: "101" }}
      >
        <div className="p-6 bg-black text-white rounded shadow-lg w-full max-w-md">
          <div className="flex justify-center mb-4">
            <p className="text-center text-white">Edit Time Activity</p>
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-2">Status</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded text-black"
            >
              <option value="" disabled>
                Select status
              </option>
              <option value="activity">Activity</option>
              <option value="rest">Rest</option>
              <option value="sleep">Sleep</option>
            </select>
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-2">Description</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded text-black"
              placeholder="Enter description"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-2">Category</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded text-black truncate"
            >
              <option value="" disabled>
                Select category
              </option>
              {categoryData?.map((cat) => (
                <option key={cat.id} value={cat.name} title={cat.name}>
                  {cat.name.length > 20
                    ? `${cat.name.slice(0, 20)}...`
                    : cat.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={handleSave}
              className="flex-1 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save
            </button>
            <button
              onClick={handleToggle}
              className="flex-1 px-4 py-2 bg-gray-500 text-white rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTimePopUp;
